import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SocialConnect from "../components/ConnectSocial";


const About = ({ data }) => (
  <Layout title={"Consultancy"}>
    <div className="about">
      <div
        className="hero"
        style={{
          background:
            "  linear-gradient(to right, rgb(19, 19, 19,0.3) 0, rgba(19, 19, 19, 0.3) 100%),url(" +
            data.strapiConsultancy.image.localFile.childImageSharp.fluid.srcWebp +
            ")",
          backgroundRepeat: "repeat, no-repeat",
          backgroundSize: "auto, cover",
          backgroundPosition: "center",
        }}
      >
        <h1>Consultancy</h1>
      </div>

      <div className="ssbody wrapper_max">
        <div className="content">
          <div
            className="page-body"
            dangerouslySetInnerHTML={{ __html: data.strapiConsultancy.body }}
          />

          <SocialConnect />
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  {
    strapiConsultancy {
      body
      image {
        localFile {
          childImageSharp {
            fluid {
              srcWebp
            }
          }
        }
      }
    }
  }
`;

export default About;
